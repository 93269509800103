import { TournamentTheme } from '@/types/tournaments';
import { TournamentSystemName } from '@/constants/tournaments.const';

export const tournamentThemeConfig = {
  [TournamentTheme.PathOfSage]: [TournamentSystemName.Euro24 as string],
  [TournamentTheme.FootballElements]: [TournamentSystemName.ChampionsLeague24Group as string],
  [TournamentTheme.UFC]: [TournamentSystemName.Ufc as string],
};

export const tournamentLogosConfig = {
  [TournamentSystemName.ChampionsLeague24Group]: {
    officialLogoSrc: '/image/logo/champions-league.svg',
    themeLogoSrc: '/image/logo/football-elements.svg',
  },
  [TournamentSystemName.Euro24]: {
    officialLogoSrc: '/image/logo/euro-2024.svg',
    themeLogoSrc: '/image/logo/path-of-sage.svg',
  },
  [TournamentSystemName.Ufc]: {
    officialLogoSrc: '/image/logo/ufc.svg',
    themeLogoSrc: null,
  },
};

export const setTournamentTheme = (slug: string | TournamentSystemName): TournamentTheme => {
  const themes = Object.keys(tournamentThemeConfig) as TournamentTheme[];
  const foundTheme = themes.find((theme) => tournamentThemeConfig[theme].includes(slug));
  return foundTheme ?? TournamentTheme.PathOfSage;
};

export const setTournamentLogos = (systemName: TournamentSystemName | string): {
  officialLogoSrc: string | null,
  themeLogoSrc: string | null,
} => {
  const isTournamentSystemName = Object.values(TournamentSystemName).includes(systemName as TournamentSystemName);

  if (isTournamentSystemName) {
    return tournamentLogosConfig[systemName as TournamentSystemName];
  }
  return {
    officialLogoSrc: null,
    themeLogoSrc: null,
  };
};
