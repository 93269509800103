import apiWrapper from '@/helpers/apiWrapper';
import { PredictionsEnum } from '@/types/tournaments';
import type {
  PlayersGetParams,
  TopPlayersPostParams,
  IExactScorePostParams,
  IGroupWinnersPostParams,
  IEventWinnerPostParams,
  IProductiveTeamPostParams,
  ILeaguePostParams,
  IFightWinnerPayload,
} from '@/types/predictions';

export default {
  getRounds: (tournamentSlug: string, userId?: number) => apiWrapper
    .get(
      `/api/v1/tournaments/${tournamentSlug}/prediction-type/${PredictionsEnum.ExactScore}`,
      { params: { user_id: userId } },
    ),

  getGroups: (tournamentSlug: string, userId?: number) => apiWrapper
    .get(
      `/api/v1/tournaments/${tournamentSlug}/prediction-type/${PredictionsEnum.Winner}`,
      { params: { user_id: userId } },
    ),

  getPlayers: (
    tournamentSlug: string,
    position: PredictionsEnum.TopGoalkeeper | PredictionsEnum.TopGoalscorer,
    params: PlayersGetParams,
  ) => apiWrapper.get(`/api/v1/tournaments/${tournamentSlug}/prediction-type/${position}`, { params }),

  getLeague: (tournamentSlug: string, userId?: number) => apiWrapper
    .get(
      `/api/v1/tournaments/${tournamentSlug}/prediction-type/${PredictionsEnum.League}`,
      { params: { user_id: userId } },
    ),

  getProductiveTeam: (tournamentSlug: string, userId?: number) => apiWrapper
    .get(
      `/api/v1/tournaments/${tournamentSlug}/prediction-type/${PredictionsEnum.ProductiveTeam}`,
      { params: { user_id: userId } },
    ),

  setKeeper: ({ tournamentSlug, playerId }: TopPlayersPostParams) => apiWrapper
    .post(`/api/v1/tournaments/${tournamentSlug}/predictions/goalkeeper/${playerId}`),

  setScorer: ({ tournamentSlug, playerId }: TopPlayersPostParams) => apiWrapper
    .post(`/api/v1/tournaments/${tournamentSlug}/predictions/scorer/${playerId}`),

  setExactScorePrediction: ({ tournamentSlug, params }: IExactScorePostParams) => apiWrapper
    .post(`/api/v1/tournaments/${tournamentSlug}/predictions/events`, params),

  setGroupWinnersPrediction: ({ tournamentSlug, groupId, params }: IGroupWinnersPostParams) => apiWrapper
    .post(`/api/v1/tournaments/${tournamentSlug}/predictions/group/${groupId}`, params),

  setEventWinnerPrediction: ({ tournamentSlug, params }: IEventWinnerPostParams) => apiWrapper
    .post(`/api/v1/tournaments/${tournamentSlug}/predictions/events/winners`, params),

  setLeaguePrediction: ({ tournamentSlug, params }: ILeaguePostParams) => apiWrapper
    .post(`/api/v1/tournaments/${tournamentSlug}/predictions/league`, params),

  setProductiveTeamPrediction: ({ tournamentSlug, params }: IProductiveTeamPostParams) => apiWrapper
    .post(`/api/v1/tournaments/${tournamentSlug}/predictions/team/${params.teamId}`),

  getFightWinner: (
    tournamentSlug: string,
    userId?: number,
  ) => apiWrapper.get(
    `/api/v1/tournaments/${tournamentSlug}/prediction-type/${PredictionsEnum.UfcWinner}`,
    { params: { user_id: userId } },
  ),

  setFightWinnerPrediction: ({ tournamentSlug, params }: IFightWinnerPayload) => apiWrapper
    .post(`/api/v1/tournaments/${tournamentSlug}/predictions/fights/winners`, params),
};
