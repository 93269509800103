<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '@/stores/auth';
import LogoIcon from '@/assets/icons/logo.svg';
import BurgerButton from '@/components/BurgerButton/BurgerButton.vue';
import LangSwitcher from '@/components/LangSwitcher/LangSwitcher.vue';
import BaseAvatar from '@/components/Base/Avatar/BaseAvatar.vue';
import { Routes } from '@/types/routes';
import { TournamentTheme } from '@/types/tournaments';
import BaseSelect from '@/components/Base/Select/BaseSelect.vue';
import type { IconName } from '@/types/icons';
import { useRoute, useRouter } from 'vue-router';

const { t } = useI18n();
const authStore = useAuthStore();

const props = defineProps<{
  theme: 'main' | TournamentTheme;
  isSport: boolean;
}>();

const isMobileNavActive = ref<boolean>(false);

const toggleMobileMenu = () => {
  isMobileNavActive.value = !isMobileNavActive.value;
};

const links = computed(() => [
  {
    label: t('tournaments'),
    name: Routes.Home,
  },
  {
    label: t('hallOfFame'),
    name: Routes.Top,
  },
  {
    label: t('faq'),
    name: Routes.FAQ,
  },
]);

const handleLinkClick = () => {
  isMobileNavActive.value = false;
};

const route = useRoute();
const router = useRouter();

const sportsOptions = computed(() => [
  {
    label: t('tournamentsTypeFootball'),
    slug: 'football',
    icon: 'football-select' as IconName,
  },
  {
    label: t('tournamentsTypeUFC'),
    slug: 'ufc',
    icon: 'ufc-select' as IconName,
  },
]);

const selectTournamentType = computed({
  get() {
    return sportsOptions.value.find((sport) => sport.slug === route.query.sport) || sportsOptions.value[0];
  },
  set(newTournamentType) {
    router.push({
      path: route.path,
      query: { sport: newTournamentType.slug },
    });
  },
});

const isSportSelect = computed(() => (
  props.isSport
  && props.theme === 'main'
));
</script>

<template>
  <header v-if="!props.isSport" class="header-onboarding container">
    <RouterLink
      :to="{name:Routes.Home}"
      class="header__logo"
    >
      <LogoIcon
        width="32"
        hight="36"
      />
    </RouterLink>
    <LangSwitcher :theme="props.theme"/>
  </header>

  <header
    v-else
    class="header"
    :class="{
      'header--pos': props.theme === TournamentTheme.PathOfSage,
      'header--fe': props.theme === TournamentTheme.FootballElements,
      'header--ufc': props.theme === TournamentTheme.UFC,
    }"
  >

    <div
      class="header__inner container"
      :class="{
      'header__inner--pos': props.theme === TournamentTheme.PathOfSage,
      'header__inner--fe': props.theme === TournamentTheme.FootballElements,
      'header__inner--ufc': props.theme === TournamentTheme.UFC,
    }"
    >
      <RouterLink
        :to="{ name:Routes.Home }"
        class="header__logo"
      >
        <LogoIcon
          width="32"
          hight="36"
        />
      </RouterLink>

      <BaseSelect
        v-if="isSportSelect"
        v-model:selected-option="selectTournamentType"
        :options="sportsOptions"
        theme="header"
        class="header__select"
      />

      <nav class="header__nav nav">
        <ul class="nav__list">
          <li
            class="nav__item"
            v-for="link of links"
            :key="link.label"
          >
            <RouterLink
              class="nav__link title-14"
              :class="{
                'nav__link--main': props.theme === 'main',
                'nav__link--pos': props.theme === TournamentTheme.PathOfSage,
                'nav__link--fe': props.theme === TournamentTheme.FootballElements,
                'nav__link--ufc': props.theme === TournamentTheme.UFC,
              }"
              active-class="nav__link--active"
              :to="{name: link.name, query: route.query}"
            >
              {{ link.label }}
            </RouterLink>
          </li>
        </ul>
      </nav>

      <div class="header__tools">
        <LangSwitcher :theme="props.theme"/>

        <RouterLink :to="{name:Routes.Account, query: route.query}">
          <BaseAvatar
            :src="authStore.user ? authStore.user.avatar : null"
            :theme="props.theme"
          />
        </RouterLink>
      </div>

      <BurgerButton
        class="header__burger-button"
        :is-active="isMobileNavActive"
        @click="toggleMobileMenu"
      />
    </div>

    <nav
      class="header__mobile-nav mobile-nav"
      :class="{'header__mobile-nav--opened': isMobileNavActive}"
    >
      <ul class="mobile-nav__list">
        <li
          class="mobile-nav__item"
          v-for="link of links"
          :key="link.label"
        >
          <RouterLink
            class="mobile-nav__link title-24"
            :to="{name: link.name, query: route.query}"
            @click="handleLinkClick"
          >
            {{ link.label }}
          </RouterLink>
        </li>
      </ul>
    </nav>
  </header>
</template>

<style lang="scss" scoped>
.header {
  position: relative;
  width: 100%;

  @include lg {
    height: $header-h-desktop;
    border-bottom: 1px solid $header-border-color;
    background-color: $header-color;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);

    &--ufc {
      background-color: $ufc-dark-20;
      border-bottom: 1px solid $ufc-primary-20;
    }
  }
}

.header-onboarding {
  z-index: $z-level-2;
  position: absolute;
  height: $header-h-mobile;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header__inner {
  z-index: $z-level-2;
  position: absolute;
  height: $header-h-mobile;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $header-border-color;
  background-color: $header-color;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);

  &--ufc {
    background-color: $ufc-dark-20;
    border-bottom: 1px solid $ufc-primary-20;
  }

  @include lg {
    position: static;
    justify-content: space-between;
    height: $header-h-desktop;
    border-bottom: none;
    background-color: transparent;
    backdrop-filter: none;
  }
}

.header__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  svg {
    width: 100%;
    height: 100%;
  }

  @include md {
    width: 32px;
    height: 32px;
  }

  @include lg {
    width: 38px;
    height: 40px;
    margin-right: 0;
  }
}

.header__nav {
  display: none;

  @include lg {
    margin:0 auto;
    display: flex;
  }
}

.header__tools {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;

  @include lg {
    gap: 16px;
    margin-left: 0;
  }
}

.header__burger-button {
  margin-left: 24px;

  @include lg {
    display: none;
  }
}

.header__mobile-nav {
  z-index: $z-level-1;
  position: absolute;
  top: -100vh;
  top: -100dvh;
  left: 0;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  display: flex;

  &--opened {
    top: 0;
  }

  @include lg {
    display: none;
  }
}

.header__select {
  flex-shrink: 0;
  margin-left: 24px;
  width: 130px;

  @include lg {
    margin-right: 0;
  }
}

.nav {
  // empty
}

.nav__list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 84px;
}

.nav__item {
  // empty
}

.nav__link {
  color: $clear-100;
  letter-spacing: 0.42px;
  transition: all 300ms ease;

  &--main {
    &:hover {
      background: $gradient;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &:active {
      color: $success-100;
      background: inherit;
      -webkit-background-clip: initial;
      -webkit-text-fill-color: initial;
    }
  }

  &--main.nav__link--active {
    color: $success-100;
  }

  &--pos {
    &:hover,
    &:focus {
      color: $yellow-100;
    }

    &:active {
      color: $yellow-120;
    }

    &--active {
      color: $yellow-120;
    }
  }

  &--fe {
    &:hover,
    &:focus {
      color: $yellow-100-fe;
    }

    &:active {
      color: $yellow-120-fe;
    }

    &--active {
      color: $yellow-120-fe;
    }
  }

  &--ufc {
    &:hover,
    &:focus {
      color: $ufc-primary-100;
    }

    &:active {
      color: $ufc-primary-80;
    }

    &--active {
      color: $ufc-primary-100;
    }
  }
}

.mobile-nav {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: $header-h-mobile;
  padding-bottom: $header-h-mobile;
  transition: all 0.2s ease-in;
  background-color: $header-color, $bg-color;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
}

.mobile-nav__list {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.mobile-nav__item {
  border-bottom: 1px solid $header-border-color;
}

.mobile-nav__link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: $clear-100;
  letter-spacing: 0.72px;
  padding: 18px 24px;
  transition: all 300ms ease;

  &:active {
    color: $success-100;
  }
}
</style>
