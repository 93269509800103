import { defineStore } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import api from '@/api';
import { getError } from '@/helpers/errorHandler';
import type { IError } from '@/types/api';
import type { LocalesEnum } from '@/types/locales';
import type {
  IUserMailingPayload,
  IUserMailingResponse,
  IUserRankingResponse,
  IUserFootballStatistics,
  IUserStatisticsResponse,
  IUserRanking, IUserUfcStatisticsItem,
} from '@/types/user';

interface IState {
  statistics: {
    footballData: IUserFootballStatistics | null;
    ufcData: IUserUfcStatisticsItem[] | null;
    loading: boolean;
  };
  ranking: {
    data: IUserRanking | null;
    loading: boolean;
  };
}

export const useUserStore = defineStore('userStore', {
  state: (): IState => ({
    statistics: {
      footballData: null,
      ufcData: null,
      loading: false,
    },
    ranking: {
      data: null,
      loading: false,
    },
  }),

  actions: {
    async mailing(payload: IUserMailingPayload): Promise<IUserMailingResponse | IError> {
      try {
        const authStore = useAuthStore();
        const { data } = await api.user.mailing(payload);

        if (authStore.user) {
          authStore.user.has_mailing = !authStore.user.has_mailing;
        }

        return data;
      } catch (error) {
        return getError(error);
      }
    },

    async getStatistics(tournamentSlug: string): Promise<IUserStatisticsResponse | IError> {
      try {
        this.statistics.loading = true;
        this.statistics.ufcData = null;
        this.statistics.footballData = null;

        const { data } = await api.user.statistics(tournamentSlug);

        this.statistics.footballData = data.data;
        this.statistics.loading = false;

        return data;
      } catch (error) {
        this.statistics.loading = false;
        return Promise.reject(getError(error));
      }
    },

    async getUfcStatistics(tournamentSlug: string): Promise<IUserUfcStatisticsItem[] | IError> {
      try {
        this.statistics.loading = true;
        this.statistics.footballData = null;
        this.statistics.ufcData = null;

        const { data } = await api.user.ufcStatistics(tournamentSlug);

        this.statistics.ufcData = data.data;
        this.statistics.loading = false;

        return data;
      } catch (error) {
        this.statistics.loading = false;
        return Promise.reject(getError(error));
      }
    },

    async getRanking(slug: string): Promise<IUserRankingResponse | IError> {
      try {
        this.ranking.loading = true;
        this.ranking.data = null;

        const { data } = await api.user.ranking(slug);

        if (data) {
          this.ranking.data = data;
        }

        return data;
      } catch (error) {
        return getError(error);
      } finally {
        this.ranking.loading = false;
      }
    },

    async changeLang(lang: LocalesEnum): Promise<{ data: true } | IError> {
      try {
        const { data } = await api.user.changeLang(lang);

        return data;
      } catch (error) {
        return getError(error);
      }
    },
  },
});
