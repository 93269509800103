import { watchEffect } from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/ru';
import { DateFormat } from '@/types/dates';
import { useI18n } from 'vue-i18n';

export const useDate = () => {
  const { locale } = useI18n();

  watchEffect(() => {
    dayjs.locale(locale.value);
  });

  const formatDate = (
    date: dayjs.ConfigType,
    format: DateFormat,
  ) => dayjs(date).locale(locale.value).format(format);

  const setLocale = (newLocale: string) => {
    locale.value = newLocale;
    dayjs.locale(newLocale);
  };

  const isDatePassed = (date: string | Date): boolean => {
    const currentDate = dayjs();
    const targetDate = dayjs(date);
    return targetDate < currentDate;
  };

  return {
    dayjs,
    setLocale,
    formatDate,
    isDatePassed,
  };
};
