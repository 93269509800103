import apiWrapper from '@/helpers/apiWrapper';
import type { IOptionsGetParams, ITournamentFromApi, ITournamentGetParams } from '@/types/tournaments';

export default {
  get: (params: ITournamentGetParams) => apiWrapper.get<{
    count: number,
    tournaments: ITournamentFromApi[],
  }>('/api/v1/tournaments/', { params }),

  getAll: (params?: IOptionsGetParams) => apiWrapper.get<{
    tournaments: ITournamentFromApi[]
  }>('/api/v1/tournaments/all', { params }),
};
