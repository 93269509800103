<template>
  <div class="base-full-screen-loader">
    <div
      class="base-full-screen-loader__spinner"
    />
  </div>
</template>

<style lang="scss" scoped>
.base-full-screen-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $bg-gradient;
  z-index: $z-modal;
}

.base-full-screen-loader__spinner {
  border: 12px solid rgba(2, 135, 209, 0.20);
  border-radius: 50%;
  border-top-color: $green;
  width: 25vw;
  max-width: 120px;
  height: 25vw;
  max-height: 120px;
  animation: spin 1s infinite linear;
  filter:blur(5px);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
<script setup lang="ts">
</script>
