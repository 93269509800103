import { type ILocalizedContent, LocalesEnum } from '@/types/locales';
import type { IGroup } from '@/types/groups';
import type { IBasket } from '@/types/baskets';
import type { TournamentSystemName } from '@/constants/tournaments.const';
import type { ISport, SportType } from '@/types/sport';

export type FilterDateType = 'all' | 'current' | 'past';

export enum StageSlug {
  Group = 'final-tournament-main-tournament',
  LeagueOfChampionsGroup = 'league-of-champions-24-group-stage',
}

export interface ITournamentStage {
  id: number;
  slug: string;
  name: ILocalizedContent;
  created_at: Date | null;
  started_at: Date | null;
  finished_at: Date | null;
  updated_at: Date | null;
  api_id: string;
  tournament_id: number;
  system_name: string;
}

export interface ITournamentFromApi {
  id: number;
  name: {
    ru: string;
    en: string;
  }
  slug: string;
  groups?: IGroup[];
  sport: ISport;
  sport_id: number;
  prediction_types: IPrediction[];
  distribute_at: Date | null;
  stages: ITournamentStage[];
  system_name: string | TournamentSystemName;
  created_at: string;
  updated_at: string;
  started_at: Date | null;
  finished_at: Date | null;
  ratings_opened_at_date: Date | null;
}

export interface ITournamentDistributionResult {
  group: IGroup | null;
  team: IBasket | null;
}

export interface ITournamentFeatures {
  hasFriendsTeam: boolean;
  hasDistribution: boolean;
}

export interface ITournament extends ITournamentFromApi {
  theme: TournamentTheme;
  distributionResult: ITournamentDistributionResult;
}

export interface IOptionsGetParams {
  sport?: SportType;
}

export interface ITournamentGetParams {
  page: number;
  limit: number;
  sport?: SportType;
  sport_id?: number;
}

export interface IPrediction {
  id: number
  name: {
    [LocalesEnum.RUS]: string;
    [LocalesEnum.ENG]: string;
  }
  system_name: PredictionsEnum;
}

export enum PredictionsEnum {
  ExactScore = 'match',
  Winner = 'group',
  TopGoalscorer = 'scorer',
  TopGoalkeeper = 'keeper',
  League = 'league',
  ProductiveTeam = 'most_productive_team',
  UfcWinner = 'fight_winner',
}

export interface ITournamentCardInfo {
  id: number;
  slug: string;
  stages: ITournamentStage[];
  name: {
    ru: string;
    en: string;
  };
  dateStart: Date | null;
  dateFinish: Date | null;
  logos: {
    officialLogoSrc: string | null;
    themeLogoSrc: string | null;
  }
  sport: ISport;
}

export enum TournamentTheme {
  PathOfSage = 'PATH_OF_SAGE',
  FootballElements = 'FOOTBALL_ELEMENTS',
  UFC = 'UFC',
}
