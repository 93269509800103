import apiWrapper from '@/helpers/apiWrapper';
import type { LocalesEnum } from '@/types/locales';
import type {
  IUserMailingPayload,
  IUserMailingResponse,
  IUserRankingResponse,
  IUserStatisticsResponse,
  IUserDivideToGroupParams,
  IUserDivideToGroupResponse,
} from '@/types/user';

export default {
  mailing: (payload: IUserMailingPayload) => apiWrapper.post<IUserMailingResponse>('/api/v1/user/mailing', payload),
  statistics: (slug: string) => apiWrapper.get<IUserStatisticsResponse>(`/api/v1/user/${slug}/statistics`),
  ufcStatistics: (slug: string) => apiWrapper.get<any>(`/api/v1/user/${slug}/ufc-statistics`),
  ranking: (slug: string) => apiWrapper.get<IUserRankingResponse>(`/api/v1/user/${slug}/ranking`),
  changeLang: (lang: LocalesEnum) => apiWrapper.put<{ data: true }>('/api/v1/user/change-lang', { lang }),

  divideToGroup: (payload: IUserDivideToGroupParams) => apiWrapper
    .post<IUserDivideToGroupResponse>('/api/v1/user/distribute', payload),

  getGroupAndTeam: (tournamentSlug: string) => apiWrapper
    .get(`/api/v1/user/tournament/${tournamentSlug}/group`),
};
