import { defineStore } from 'pinia';
import api from '@/api';
import { getError } from '@/helpers/errorHandler';
import type { ISport } from '@/types/sport';
import type { IError } from '@/types/api';

interface IState {
  isLoading: boolean;
  data: null | ISport[];
}

export const useSportsStore = defineStore('sportsStore', {
  state: (): IState => ({
    isLoading: false,
    data: null,
  }),

  getters: {
    slugToId(): Record<string, number> {
      const obj: Record<string, number> = {};
      if (this.data) {
        this.data.forEach((sport) => {
          obj[sport.slug] = sport.id;
        });
      }
      return obj;
    },

    slugToSport(): Record<string, ISport> {
      const obj: Record<string, ISport> = {};
      if (this.data) {
        this.data.forEach((sport) => {
          obj[sport.slug] = sport;
        });
      }
      return obj;
    },
  },

  actions: {
    async get(): Promise<ISport[] | IError> {
      try {
        this.isLoading = true;
        this.data = null;

        const { data } = await api.sports.get();

        this.data = data.sports;
        this.isLoading = false;

        return this.data;
      } catch (error) {
        this.data = [];
        this.isLoading = false;
        return Promise.reject(getError(error));
      }
    },
  },
});
