<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { type LocalesEnum } from '@/types/locales';
import { type ISport, SportType } from '@/types/sport';

const props = defineProps<{
  sports: ISport[];
}>();

const { locale } = useI18n();

const images = {
  [SportType.Football]: '/image/onboarding/football.svg',
  [SportType.UFC]: '/image/onboarding/ufc.svg',
};

const secondImages = {
  [SportType.Football]: '/image/onboarding/football-hover.svg',
  [SportType.UFC]: '/image/onboarding/ufc-hover.svg',
};

const sportList = computed(() => props.sports.map((sport) => ({
  label: sport.name[locale.value as LocalesEnum],
  image: images[sport.slug],
  imageSecond: secondImages[sport.slug],
  slug: sport.slug,
})));
</script>

<template>
  <div class="onboarding container">
    <RouterLink
      v-for="sport in sportList"
      :key="sport.label"
      :to="{ query: { sport: sport.slug } }"
      class="onboarding__link"
      :style="{
       '--image': `url(${sport.image})`,
       '--image-second': `url(${sport.imageSecond})`,
     }"
    >
      <div class="onboarding__image"/>
      <span class="onboarding__label title-24">{{ sport.label }}</span>
    </RouterLink>
  </div>
</template>

<style scoped lang="scss">
.onboarding {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include lg {
    flex-direction: row;
    gap: 20px;
  }

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $clear-100;
    text-decoration: none;
    padding-top: 16px;
    padding-bottom: 32px;
    width: 90%;
    max-width: 85%;

    &:hover {
      .onboarding__image {
        background-image: var(--image-second);
      }

      .onboarding__label {
        background: $gradient-light;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid #3C689D99;
    }

    @include md {
      max-width: 320px;
    }

    @include lg {
      &:not(:last-child) {
        border-bottom: none;
      }
    }
  }

  &__image {
    width: 75%;
    aspect-ratio: 1 / 1;
    background-image: var(--image-second);
    background-size: cover;
    background-position: center;
    transition: background-image 0.3s ease;

    @include md {
      width: 100%;
    }

    @include lg {
      background-image: var(--image);
    }
  }

  &__label {
    background: $gradient-light;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 24px;

    @include lg {
      background: transparent;
      -webkit-background-clip: inherit;
      -webkit-text-fill-color: inherit;
    }
  }
}
</style>
