import type { ILocalizedContent } from '@/types/locales';

export enum SportType {
  Football = 'football',
  UFC = 'ufc',
}

export interface ISport {
  id: number;
  name: ILocalizedContent;
  slug: SportType;
  created_at: string;
  updated_at: string;
  system_name: string;
}
